@import '../../variables';

main {
  select[disabled] {
    background-color: #eee !important;
  }

  &[dir='rtl'] {
    ol {
      padding-right: 16px;
    }

    .accordionItemContent {
      ul {
        padding-right: 32px;
      }
    }

    .accordionHeader {
      [aria-expanded='false'] {
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 2px;
          transform: translateY(-0.5px);
          width: 15px;
          height: 1px;
          background: black;
        }

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 2px;
          transform: translateY(-0.5px);
          width: 15px;
          height: 1px;
          background: black;
          transform: rotate(90deg);
        }
      }

      [aria-expanded='true'] {
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 2px;
          transform: translateY(-0.5px);
          width: 15px;
          height: 1px;
          background: black;
        }
      }
    }
  }

  &[dir='ltr'] {
    .accordionHeader {
      [aria-expanded='false'] {
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          right: 2px;
          transform: translateY(-0.5px);
          width: 15px;
          height: 1px;
          background: black;
        }

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          right: 2px;
          transform: translateY(-0.5px);
          width: 15px;
          height: 1px;
          background: black;
          transform: rotate(90deg);
        }
      }

      [aria-expanded='true'] {
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          right: 2px;
          transform: translateY(-0.5px);
          width: 15px;
          height: 1px;
          background: black;
        }
      }
    }
  }
}

.wifiConnectContainer {
  padding: 0 !important;
  padding-bottom: 123px !important;
  overflow-x: hidden;

  .raySelectInput {
    color: #000;
    background: #fffffe; // Tricking Firefox to show first option (disabled) as white (if we set it to #fff, it's ignored)
  }

  .footer {
    border-top: 1px solid rgb(230, 230, 230);
  }

  span {
    font-size: $ray-text-size-body;
  }

  .errorPermissions {
    padding: 0 8px;
  }

  .logoutLink {
    outline: none;
    background: none;
    border: none;
    color: $ray-color-blue-50;
    padding: 0;
    margin-top: 40px;
  }

  .logoutLink:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .nav {
    height: 80px;

    .logoutBtn {
      min-width: 85px;
    }

    @media (max-width: 600px) {
      margin-bottom: 48px;
    }

    @media (min-width: 600px) {
      margin-bottom: 65px;
    }

    border-bottom: 1px solid rgb(230, 230, 230);
    display: flex;
    justify-content: space-between;

    @media (max-width: 600px) {
      padding: 21px 16px 19px 16px;
    }

    @media (min-width: 600px) {
      padding: 21px 64px 19px 64px;
    }

    .weworkLogo {
      margin-bottom: 8px;
      display: flex;
      align-items: center;

      .logo {
        display: inline-block;
        width: 93px;
        height: 21px;
        background: url('../../../public/wework-logo.svg') center no-repeat;
        background-size: contain;
        margin: 0 5px;
      }
    }
  }

  .errorMessage {
    color: $ray-color-red-60;
  }

  .pageTitle {
    text-align: left;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 32px;

    @media (max-width: 600px) {
      font-size: 32px;
    }
  }

  .networkName {
    text-align: center;
    margin-bottom: $ray-spacing-less-common-block-m 0;
  }

  .setupInstructionsTitle {
    text-align: center;
    margin-top: $ray-spacing-common-block-l;
    margin-bottom: $ray-spacing-common-block-m;
  }

  .textFieldWrapper {
    margin-top: $ray-spacing-least-common-block-s;
    margin-bottom: $ray-spacing-least-common-block-s;

    button {
      padding: $ray-spacing-least-common-block-s
        $ray-spacing-less-common-block-m;
      border-radius: 0 $ray-spacing-less-common-block-s
        $ray-spacing-less-common-block-s 0;
    }
  }

  .centered {
    margin: 0 auto;
  }

  .centeredList {
    margin: 0 auto;

    ul {
      color: $ray-color-text-dark;
      list-style: inherit;
      padding-left: $ray-spacing-common-block-m;
    }
  }

  .mainTabs,
  .tabList {
    text-align: center;
    margin-bottom: $ray-spacing-less-common-block-m;
    border: none !important;

    button {
      background: none;
      border: none;
      border-bottom: 1px solid $ray-color-blue-10;
      min-width: 50%;
      color: $ray-color-text-dark;
      font-size: $ray-text-size-body;
      height: $ray-spacing-less-common-block-l;
      margin: 0;
      padding: 0;

      &:hover,
      &[aria-selected='true'] {
        background: none;
        color: $ray-color-blue-50;
        border-color: $ray-color-blue-50;
      }
    }
  }

  .paginationButton {
    padding: $ray-spacing-least-common-block-s $ray-spacing-less-common-block-m;
    border-radius: 0 $ray-spacing-less-common-block-s
      $ray-spacing-less-common-block-s 0;
    text-align: center;
  }

  .corpTabList {
    text-align: center;
    margin-bottom: $ray-spacing-less-common-block-m;
    border: none !important;

    button {
      background: none;
      border: none;
      border-bottom: 1px solid $ray-color-blue-10;
      min-width: 33%;
      color: $ray-color-text-dark;
      font-size: $ray-text-size-body;
      height: $ray-spacing-less-common-block-l;
      margin: 0;
      padding: 0;

      &:hover,
      &[aria-selected='true'] {
        background: none;
        color: $ray-color-blue-50;
        border-color: $ray-color-blue-50;
      }
    }
  }

  .tabPanels {
    font-size: $ray-text-size-body-small;
    line-height: $ray-text-line-height-body;

    ul {
      color: $ray-color-text-dark;
      list-style: inherit;
      padding-left: $ray-spacing-common-block-m;
    }

    h4 {
      font-size: $ray-text-size-body;
    }
  }

  .tabContent {
    margin: 0 auto;
  }

  .siteFooter {
    padding-top: 150px;
    padding-bottom: 30px;

    @media (min-width: 1195px) {
      padding-top: 75px;
    }

    &__contact {
      font-family: Apercu Mono, Avenir, -apple-system, BlinkMacSystemFont,
        Helvetica Neue, Helvetica, Calibri, Roboto, Arial, sans-serif;
      font-weight: 400;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-rendering: optimizeLegibility;
      margin-top: 2rem;
      color: $ray-color-black;
      line-height: $ray-text-line-height-body-small;
      font-size: $ray-text-size-body-small;
    }

    &__legal_container {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;

      @media (min-width: 1195px) {
        flex-direction: row;
        justify-content: flex-end;
      }

      .legal_text_wrapper {
        display: flex;
        flex-direction: column;
        margin-right: 1.5rem;
      }
    }

    &__legal_links {
      display: flex;
      justify-content: flex-start;

      @media (min-width: 1195px) {
        justify-content: flex-end;
      }
    }

    &__legal_link_item {
      margin-right: 1.25rem;
    }

    &__legal_copyright {
      color: $ray-color-gray-60;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;

      .only_visible_in_phone {
        display: block;

        @media (min-width: 600px) {
          display: none;
        }
      }
    }

    &__we_logo {
      width: 48px;
      height: 48px;
      background: url('../../../public/we-logo-circle.svg') center no-repeat;
      background-size: contain;

      @media (min-width: 1195px) {
        margin-top: 5px;
      }

      @media (max-width: 1195px) {
        margin-top: 32px;
      }
    }
  }

  .notification {
    background-color: $ray-color-blue-03;
    padding: $ray-spacing-less-common-block-m $ray-spacing-common-block-s;
    border-radius: $border-radius-base;
    margin-bottom: $ray-spacing-common-block-s;
    font-size: $ray-text-size-body;
    width: 100%;

    text-overflow: ellipsis;
    overflow: hidden;
    ul {
      list-style-type: disc;
      margin-left: 18px;
    }
  }

  .successfulNotification {
    background-color: $ray-color-green-10;
  }

  .warningNotification {
    background-color: $ray-color-yellow-50;
  }

  .deviceName {
    white-space: nowrap;
  }

  .formErrorMessage {
    color: $ray-color-red-60;
    display: block;
  }

  .compactSelect {
    width: 50%;
  }

  .selectEditable select {
    border: none;
    width: 100%;
    margin: 0;
  }

  .selectEditable div {
    position: absolute;
    background-color: none;
    top: 0px;
    left: 0px;
    width: 80%;
    height: 90%;
    padding: 1px;
    border: none;
  }

  .selectEditable select:focus,
  .selectEditable input:focus {
    outline: none;
  }

  .errorMsg {
    background-color: $ray-color-red-10;
    margin-bottom: $ray-spacing-common-block-m;
  }

  .errorMsgInReportModal {
    background-color: $ray-color-red-10;
  }

  .errorMsgInTable {
    background-color: $ray-color-red-10;
  }

  .inLineDeviceNameInput {
    width: 100px;
  }

  .inLineDeviceSelect {
    width: 150px;
  }

  .formLoader {
    text-align: center;
  }

  .horizontalLine {
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .searchByMacLoader {
    margin-top: 10px;
    text-align: center;
  }

  .modalBackdrop {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 998;
  }

  .modal {
    display: block;
    width: calc(100% - 50px);
    background-color: $ray-color-white;
    border-radius: $border-radius-base;
    padding: $ray-spacing-less-common-block-m;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 999;
    transform: translateX(-50%) translateY(-50%);

    @media screen and (min-width: 500px) {
      width: 400px;
    }
  }

  .modalTitle {
    text-align: center;
    font-weight: bold;
    margin-bottom: 1em;
    font-size: 1.3em;
  }

  .modalFooter {
    display: flex;
    justify-content: center;
    margin-top: $ray-spacing-common-block-s;

    @media screen and (max-width: 350px) {
      flex-direction: column;

      button {
        width: 100%;
      }

      button:first-child {
        margin-bottom: 5px;
      }
    }
  }

  .button {
    border-radius: 0.25rem !important;
    padding: $ray-spacing-least-common-block-s $ray-spacing-less-common-block-m;
  }

  .tableCol {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100px;

    @media screen and (max-width: 400px) {
      max-width: 60px !important;
    }
  }

  .corpTableCol {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 180px;

    @media screen and (max-width: 400px) {
      max-width: 60px !important;
    }
  }

  .tableColMac {
    white-space: nowrap;
    max-width: 150px;
  }

  .menuBtn {
    display: block;
    width: 24px;
    height: 20px;
    outline: none;
    border: none;
    background: url('../../../public/dots.png') center no-repeat;
    background-size: contain;
  }

  .menuBtn:hover {
    cursor: pointer;
  }

  .actionBtn {
    color: $ray-color-blue-50;
    line-height: 54px;
    padding: 0 15px;
    text-decoration: none;
    font-size: $ray-text-size-body;
    min-width: fit-content;
    text-align: right;
  }

  .actionBtn:hover {
    cursor: pointer;
  }

  .actions {
    border-radius: 4px;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.11);
    background: white;
    min-width: 106px;
    height: 80px;
    padding: 9px 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    left: -5px !important;
    bottom: -5px !important;

    .actionsItem {
      vertical-align: middle;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: black;

      span {
        font-size: 14px !important;
        padding-right: 10px;
      }
    }

    .editAction {
      &::before {
        content: '';
        width: 16px;
        height: 16px;
        margin-right: 8px;
        background: url('../../../public/edit-icon.png') center no-repeat;
        background-size: contain;
      }
    }

    .deleteAction {
      &::before {
        content: '';
        width: 16px;
        height: 16px;
        margin-right: 8px;
        background: url('../../../public/delete-icon.png') center no-repeat;
        background-size: contain;
      }
    }
  }

  .mediumTitle {
    margin-top: 32px;
    margin-bottom: 16px;

    h3 {
      font-size: 20px !important;
    }

    .subtitle {
      font-weight: normal;
      font-size: 14px;
    }
  }

  .input {
    margin-bottom: 24px;
  }

  ol {
    list-style: decimal;
    font-size: 14px;
    margin-left: 18px;

    li {
      .listItem {
        position: relative;
        left: 10px;
      }
    }
  }

  .devicesList {
    margin-top: 9px;
    padding-left: 0 !important;
    font-size: $ray-text-size-body;

    > li {
      list-style: none;

      span {
        margin: 0 10px;
      }

      &:before {
        display: inline-block;
        content: '';
        width: 16px;
        height: 16px;
        background: url('../../../public/ok-icon.png') center no-repeat;
        background-size: contain;
        transform: translateY(2px);
      }
    }
  }

  .addDeviceBtn {
    min-width: 128px;
    margin-bottom: 14px;
    white-space: normal;
  }

  .resetButton {
    min-width: 171px;
    margin-bottom: 16px;
    white-space: normal;
  }

  .passwordFieldWrapper {
    display: inline-block;
    white-space: nowrap;
    margin: 0 10px;
  }

  .passwordField {
    display: block;
    margin-top: 10px;
  }

  .passwordFieldBtn {
    background-size: contain;
    display: inline-block;
    width: 16px;
    height: 12px;
    margin: 0 10px;

    &:hover {
      cursor: pointer;
    }
  }

  .hideBtn {
    background: url('../../../public/hide-icon.svg') center no-repeat;
  }

  .showBtn {
    background: url('../../../public/show-icon.svg') center no-repeat;
  }

  .accordion {
    .accordionItem {
      .accordionHeader {
        position: relative;
        font-size: $ray-text-size-body;
        border-top: 1px solid rgb(230, 230, 230);

        .accordionItemBtn {
          padding: 12px 0;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .accordionItemContent {
        padding-bottom: 16px;
      }
    }

    &:last-child {
      border-bottom: 1px solid rgb(230, 230, 230);
    }
  }

  .browserList {
    list-style-type: circle;
  }

  .middleHeader {
    margin: 0 auto !important;

    h2 {
      text-align: inherit;
    }
  }
}
