@import 'variables';

body,
html,
#root {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

// global toast styling
// TODO: swap out when toast is in ray-product-react
// https://jira.weworkers.io/browse/WEB-781
.toast {
  background-color: $ray-color-blue-10;
  text-align: center;
  color: $ray-color-text-dark;
}
