// ray colors https://ray.wework.com/foundations/colors/
$ray-color-black: rgb(0, 0, 0);
$ray-color-blue-03: rgb(240, 240, 255);
$ray-color-blue-10: rgb(204, 204, 255);
$ray-color-blue-20: rgb(153, 153, 255);
$ray-color-blue-50: rgb(0, 0, 255);
$ray-color-gray-03: rgb(247, 247, 247);
$ray-color-gray-10: rgb(227, 227, 227);
$ray-color-gray-30: rgb(179, 179, 179);
$ray-color-gray-60: rgb(102, 102, 102);
$ray-color-red-60: rgb(204, 0, 0);
$ray-color-red-10: rgb(255, 204, 204);
$ray-color-white: rgb(255, 255, 255);
$ray-color-green-10: rgb(210, 249, 218);
$ray-color-yellow-50: rgb(255, 213, 0);

// ray spacing https://ray.wework.com/foundations/spacing/
$ray-spacing-common-block-xs: 0.5rem;
$ray-spacing-common-block-s: 1rem;
$ray-spacing-common-block-m: 2rem;
$ray-spacing-common-block-l: 4rem;
$ray-spacing-less-common-block-s: 0.25rem;
$ray-spacing-less-common-block-m: 1.5rem;
$ray-spacing-less-common-block-l: 3rem;
$ray-spacing-least-common-block-s: 0.75rem;
$ray-spacing-least-common-block-m: 1.25rem;
$ray-spacing-least-common-block-l: 2.5rem;

// ray text colors https://ray.wework.com/foundations/typography/#standard-text-colors
$ray-color-text-light: $ray-color-gray-30;
$ray-color-text-medium: $ray-color-gray-60;
$ray-color-text-dark: $ray-color-black;

// ray font sizes https://ray.wework.com/foundations/typography/#full-scale
$ray-text-size-body: 1rem;
$ray-text-size-body-large: 1.25rem;
$ray-text-size-body-small: 0.875rem;
$ray-text-size-body-x-small: 0.75rem;
$ray-text-line-height-body: 28px;
$ray-text-line-height-body-large: 32px;
$ray-text-line-height-body-small: 24px;
$ray-text-line-height-body-x-small: 20px;

$border-radius-base: 4px;
