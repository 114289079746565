.block {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 430px) {
    flex-direction: column-reverse;
  }

  @media screen and (min-width: 1200px) and (max-width: 1350px) {
    flex-direction: column-reverse;
  }
}
