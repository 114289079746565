@import '../../../../variables';

.link {
  text-decoration: underline;
  display: inline-block;
  height: fit-content;
  padding-left: 20px;
  margin-top: 10px;

  & > span {
    font-size: $ray-text-size-body-small;
  }
}

main {
  &[dir='rtl'] {
    .rightLink {
      margin-right: auto;
    }
  }

  &[dir='ltr'] {
    .rightLink {
      margin-left: auto;
    }
  }
}

.rightLink {
  transform: translateY(-16px);
}
